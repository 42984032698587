import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

import * as ParamsStore from 'stores/Params';

@connect(state => ({resources: state.params.resources}))
export default class CmsText extends React.Component {
    static propTypes = {
        resourceKey: PropTypes.string.isRequired,
        resources: PropTypes.object,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {resourceKey, resources} = this.props;
        let text = resources && resourceKey in resources ? resources[resourceKey] : '';

        if (text.match(/{(.*?)}/g)) {
            let fragments = text.split(/{(.*?)}/g);
            return (<React.Fragment>
                {fragments.map((f, index) => f && f.includes('|') 
                    ? <NavLink key={`ct_${index}`} to={f.split('|')[1]}>{f.split('|')[0]}</NavLink> 
                    : <React.Fragment key={`ct_${index}`}>{f}</React.Fragment>)}
            </React.Fragment>)
        }

        return resources && resourceKey in resources && <React.Fragment>{resources[resourceKey]}</React.Fragment>;
    }
}
