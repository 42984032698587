import * as React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import PageMetaData from 'components/PageMetaData';
import TaskBar from 'components/taskbar';
import * as NetworkStore from 'stores/Network';
import $ from 'jquery';
import 'less/home.less';
import {BrComponent} from '@bloomreach/react-sdk';

@connect(state => (
    {
        network: state.network,
    }), _.merge({},
    NetworkStore.actionCreators,
)
)
export default class Home extends React.Component {
    static propTypes = {
        network: NetworkStore.StateShape,
    };

    static displayName = 'Home';

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // Hack: Homepage only.
        $('body').css({overflowX: 'hidden'});
    }

    componentWillUnmount() {
        $('body').css({overflowX: 'visible'});
    }

    render() {
        const {network: {isLoggedIn}} = this.props;

        return (
            <div id="home-page">
                <PageMetaData
                    title="PartsSource - Healthcare Products and Solutions"
                    pageType="homepage"
                    trackAnalytics={true}
                    rootPage={true}
                    canonical="https://www.partssource.com/"
                />

                <div className="top-cms-container">
                    <BrComponent path={'main/top'} />
                </div>

                {isLoggedIn ?
                    <React.Fragment>
                        <div className="taskbar-container">
                            <TaskBar />
                        </div>
                    </React.Fragment> : null}

                <div className="middle-cms-container">
                    <div className="row-fluid">
                        <div className="col-xs-12 col-md-6">
                            <BrComponent path={'main/left'} />
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div>
                                <BrComponent path={'main/right'} />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="guided-search-container">
                    <BrComponent path={'main/search'} />
                </div>

                <div className="main-cms-container" style={{marginTop: '24px'}}>
                    <BrComponent path={'main/container'} />
                </div>
            </div>
        );
    }
}
